import React, { useState, useContext, useEffect } from 'react';
import { AppContext, AuthContext } from 'Context';
import { useNavigate } from 'react-router-dom';
import './UserM.css';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { removeDummy, clearAllDummys, saveDummy, clearUploadDetails, saveUploadInfo } from 'store/store';
import { CircularProgress as MuiCircularProgress, withStyles, makeStyles, Grid, IconButton, CircularProgress } from '@material-ui/core';
import { baseUrl, firebaseCredentials } from 'Api';
import { ReactComponent as HiddenIcon } from 'Assets/eye.slash.svg';
import { ref, get, getDatabase, off, onValue, update } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import axios from 'axios';
import { InfoModal } from '../private/dashboard/info-modal/index';

function ManagerList({ isLoading, fetchMangerData, getToken, hiddenIndices, setHiddenIndices, userListData, setUserListData }) {
  const dispatch = useDispatch();
  const { handleClick, setIamUploading, firebaseDatabase, setEncounterLoading, autoSignIn, setbigUserData, isTokenChange, iamUploading, getSubscription, setmessaged, setmangerFunctionData, setUploadContainer, setAppSnackbar } = useContext(AppContext);
  const navigate = useNavigate();
  const tokens = getToken();
  const [isOpenInfoModal, setInfoModal] = useState(false);
  const [userObject, setUserObject] = useState();
  const [userIamChanging, setuserIamChanging] = useState(false);
  const [visibleEye, setVisibleEye] = useState(Array(userListData?.data?.length).fill(false));
  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  const changeFieldFromFirebase = (draft_id, statusString) => {
    const dataToUpdate = {
      [draft_id]: {
        status: statusString,
      },
    };
    update(databaseRef, dataToUpdate);
  };
  const clearAllDummy = () => {
    dispatch(clearAllDummys());
};
  const switchingFunction = async (obj) => {
      try {
        const formData = new FormData();
        formData.append('token', tokens);
        formData.append('user_id', obj.userId);
        formData.append('user_token', obj.token);
        const response = await axios.post(`${baseUrl}/managerUserLogs`, formData);
        if (response?.data?.success === true) {
          clearAllDummy();
          handleClick(obj);
          localStorage.setItem('firstname', obj?.firstname || '');
          localStorage.setItem('lastname', obj?.lastname || '');
          navigate('/dashboard');
          getSubscription();
          autoSignIn();
        } else {
          setmessaged(response?.data?.message);
          setmangerFunctionData(true);
        }
      } catch (error) {
        console.error(error); // Handle any errors
      }
  };
  const uploadPercentages = useSelector((state) => state.upload);
  const managerTokenFunction = async (obj) => {
    console.log(uploadPercentages);
    const uploadingOrNot = Object.values(uploadPercentages).some(
      (value) => typeof value === 'number' || value === 'Starting Upload'
    );
    if (!uploadingOrNot) {
      clearAllDummy();
      switchingFunction(obj);
    } else {
      setmessaged('You are uploading, so not able to access');
      setInfoModal(true);
    }
  };
  useEffect(() => {
    fetchMangerData();
  }, [isTokenChange]);

  useEffect(() => {
    const databaseRefManUsers = ref(firebaseDatabase, `manager_user_list_updates/${localStorage.getItem('id')}`);
    const dataCallbackMans = (snapshot) => {
      if (snapshot.exists()) {
        fetchMangerData();
      }
    };
    onValue(databaseRefManUsers, dataCallbackMans);
    return () => {
      off(databaseRefManUsers, 'value', dataCallbackMans);
    };
  }, []);
  const updateDummy = (dummy, status) => {
    if (status === 1) {
      dispatch(saveDummy({ [dummy?.draft_id]: dummy }));
    } else {
      dispatch(removeDummy(dummy));
    }
  };
  const handleUserData = (obj) => {
        // temp fix for production #TODO akash
        localStorage.setItem('temp_user_id', obj?.userId);
        // end temp fix
        setbigUserData(obj);
        navigate('/affiliate');
  };
  const closeToast = (draft_id, message, type) => {
    toast.update(draft_id, { closeButton: false, render: message, type: type === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR, autoClose: 3000 });
    setUploadContainer((prevUploadContainer) => prevUploadContainer.filter((id) => id !== draft_id));
  };
  const updateUploadPercentages = (draft_id, data) => {
    dispatch(saveUploadInfo({ [draft_id]: data }));
  };
  const data = useSelector((state) => state.deleteUpload);
  const deleteAdded = (userObject) => {
    setuserIamChanging(true);
    setEncounterLoading(true);
    const draftIds = Object.keys(uploadPercentages);
    const deletePromises = [];
    draftIds.forEach((draftId) => {
      const uploadStatus = uploadPercentages[draftId];
      // Check if the uploadStatus is not "Please wait"
      if (uploadStatus !== 'Please wait') {
        const deletePromise = axios
          .post(`${baseUrl}/deleteDraftContent`, {
            token: getToken(),
            draft_id: [draftId], // Pass the current draftId
            content_id: [],
          })
          .then((response) => {
            changeFieldFromFirebase(draftId, 'DELETED');
            updateDummy(draftId, 0);
            updateUploadPercentages(draftId, 'Cancelled');
            closeToast(draftId, 'Upload has been canceled by user', 'error');
            return response;
          })
          .catch((error) => {
            console.error(`Error deleting items for draftId ${draftId}:`, error);
            throw error;
          });
        deletePromises.push(deletePromise);
        dispatch(clearUploadDetails());
      }
    });
    Promise.all(deletePromises)
      .then(() => {
        setEncounterLoading(false);
        setInfoModal(false);
        setIamUploading(true);
        setuserIamChanging(false);
        switchingFunction(userObject);
      })
      .catch((error) => {
        console.error('Error deleting items:', error);
      });
  };
  const CircularProgress = withStyles({
    circle: {
      color: '#5aa5ef !important'
    }
  })(MuiCircularProgress);

  const toggleHidden = (index) => {
    if (hiddenIndices.includes(index)) {
      setHiddenIndices(hiddenIndices.filter((i) => i !== index));
    } else {
      setHiddenIndices([...hiddenIndices, index]);
    }
  };
  const hideManagerUser = (userId, index) => {
    const updatedVisibleEye = [...visibleEye];
    updatedVisibleEye[index] = true;
    setVisibleEye(updatedVisibleEye);
    const API_URL = `${baseUrl}/hideManagerUser`;
    const data = {
      token: getToken(),
      user_id: userId
    };
    axios.post(API_URL, data)
      .then((response) => {
        toggleHidden(userId);
        setAppSnackbar({ isVisible: true, message: response?.data?.message, type: 'error' });
        setVisibleEye((prevVisibleEye) => {
          const updatedVisibleEye = [...prevVisibleEye];
          updatedVisibleEye[index] = false;
          return updatedVisibleEye;
        });
      })
      .catch((error) => {
        setAppSnackbar({ isVisible: true, type: 'error', message: error?.response?.message });
      });
  };
  return (
    <div>
      {
        isOpenInfoModal && (
          <InfoModal
            isVisible={isOpenInfoModal}
            title="Uploading is in progress"
            onHide={() => { setInfoModal(false); }}
            continueButton="Continue with upload"
            userIamChanging={userIamChanging}
            // content="You are now deleting, all videos and photos will now be a draft until you
            // Add them to a new category. Are you sure you want to continue?"
            actionButton="Access user anyway"
            action={() => deleteAdded(userObject)}
            isDelete
          />
        )
      }
    <div className="the_Hieghter">
      {isLoading ? (
        <div style={{ display: 'flex' }}>
          <CircularProgress />
        </div>
      ) : (
        userListData?.data?.map((obj, i) => (
          !hiddenIndices.includes(obj?.userId) && (
          <div className="flexter w100" key={i}>
            <div className="rounder">{obj?.firstname?.charAt(0)?.toUpperCase()}</div>
            <div>
              <p className="UserName">
                {obj?.firstname?.charAt(0)?.toUpperCase() + obj?.firstname?.slice(1)}
                {' '}
                {obj?.lastname?.charAt(0)?.toUpperCase() + obj?.lastname?.slice(1)}
              </p>
              <p className="mySmallMail">
                {obj?.email}
              </p>
            </div>
            {obj?.profile_status === 'Incomplete'
              ? <button onClick={() => handleUserData(obj)} className="Non_access">Unpaid</button>
              : <button onClick={() => { setUserObject(obj); managerTokenFunction(obj); }} className="accessHim">Access</button>}
                <HiddenIcon onClick={() => hideManagerUser(obj?.userId, i)} className={visibleEye[i] ? 'visibleEye' : 'hiddenX'} />
          </div>
          )
        ))
      )}
    </div>
    </div>

  );
}

export default ManagerList;
