import {
  Box,
  CircularProgress,
  DialogTitle,
  Fade
} from '@material-ui/core';
import { baseUrl, dataFetcher, endpoints } from 'Api';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import noPhotoAvailable from 'Assets/no-image-available.png';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import AXIOS from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { Text } from '../../../../Components/text';
import AlbumDetails from '../../../../Components/upload-modal/components/AlbumDetails';
import AlbumSummary from '../../../../Components/upload-modal/components/AlbumSummary';
import ProgressTracker from '../../../../Components/upload-modal/components/ProgressTracker';
import EditVideo from '../../../../Components/upload-modal/components/VideoDetails';
import EditVisibility from '../../../../Components/upload-modal/components/VideoSummary';
import styles from '../../../../Components/upload-modal/index.module.css';
import LoaderContent from '../../../../Components/upload-modal/model-dialog-content/LoaderContent';
import EditCategory from '../../../../Components/upload-modal/model-dialog-content/UpdateCategoryPlaylist';

const Modal = ({ isVisible, onHide, onClose, contentDetails, uploadType, makeEveryThingNull, uniqueId = null, setContentDetails }) => {
  const { setAppSnackbar, subscriptionDetails, setUserDetails, userDetails, langText, setPageLoad, setSingleUpload,
    isSinglUepload, uploadingVideoDetails, setSingleEdit, setUploadingVideoDetails, selectedItems, pubLoader, setPubLoader,
    setSelectedItems } = useContext(AppContext);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [videoUploadData, setVideoUploadData] = useState(undefined);
  const [uploadedData, setUploadedData] = useState({
    category_id: contentDetails?.category_id,
    description: undefined,
    group_id: contentDetails?.group_id,
    playlist_id: contentDetails?.playlists,
    publish_date: undefined,
    status: undefined,
    subcategory_id: contentDetails?.subcategory_id,
    thumbnails: undefined,
    title: undefined,
    token: undefined,
    video_file: undefined,
    video_id: undefined,
    visibility: contentDetails?.visibility,
    category_name: contentDetails?.Category,
    subcategory_name: contentDetails?.SubCategory,
  });
  const uploadText = langText?.upload;
  const commonText = langText?.common;
  const visibilityText = langText?.visibility;
  const [videoID, setVideoID] = useState(undefined);
  const videoVisibilityOptions = [
    { name: 'All Viewers' || visibilityText?.publicDescription, value: '2' },
    { name: visibilityText?.privateDescription || 'Only I can view (Private)', value: '1' },
    { name: visibilityText?.groupDescription || 'Choose a group to share with', value: '3' },
  ];
  const defaultVideoDetails = {
    name: contentDetails?.title,
    duration: contentDetails?.video_duration,
    size: contentDetails?.video_size,
  };
  const combinedVideoThumb = contentDetails?.thumbnail_list ? contentDetails?.thumbnail_list?.replace(/\\/g, '')?.replace(/"/g, '')?.slice(1, -1)?.split(',') : contentDetails?.thumbnails?.concat(contentDetails?.thumbnailList); // combine suggested and selected thumbnail
  const uniqueVideoThumb = Array.isArray(combinedVideoThumb) ? combinedVideoThumb?.length && combinedVideoThumb?.filter((item, pos) => combinedVideoThumb?.indexOf(item) === pos) : []; // select unique thumbnails only
  const [videoIsPublished, setVideoPublishStatus] = useState(false);
  const defaultVideoVisibility = contentDetails?.visibility;
  const [videoVisibility, setVideoVisibility] = useState(defaultVideoVisibility);
  const [videoDetails, setVideoDetails] = useState(defaultVideoDetails);
  const [videoGroup, setVideoGroup] = useState(contentDetails?.group_id);
  const [loader, setLoader] = useState(false);
  const [videoVisibilityData, setVideoVisibilityData] = useState(undefined);
  const [defaultVideoThumbnail, setDefaultVideoThumbnail] = useState(contentDetails?.thumbnails || noPhotoAvailable);
  const [videoTitle, setVideoTitle] = useState(contentDetails?.title);
  const [videoDescription, setVideoDescription] = useState(contentDetails?.description);
  const [videoIsUploaded, setVideoUploadStatus] = useState(false);
  const [source, setSource] = useState(undefined);
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);
  const [videoUploadingCompleted, setVideoUploadingCompleted] = useState(false);
  const [runLoader, setrunLoader] = useState(false);
  const [videoFile, setVideoFile] = useState(contentDetails?.video_file);
  const [videoThumbnails, setVideoThumbnails] = useState(uniqueVideoThumb);
  const [thumbnailType, setThumbnailType] = useState('normal');
  const [customVideoThumbnails, setCustomVideoThumbnails] = useState([]);
  const [chooseAlbumStep, setChooseAlbumStep] = useState(1);
  const [albumTitle, setAlbumTitle] = useState(contentDetails?.title);
  const [albumDescription, setAlbumDescription] = useState(contentDetails?.description);
  const [albumThumbnails, setAlbumThumbnails] = useState([]);
  const [customAlbumThumbnails, setCustomAlbumThumbnails] = useState([]);
  const [defaultAlbumThumbnail, setDefaultAlbumThumbnail] = useState({ url: contentDetails?.icon || noPhotoAvailable });
  const [albumUploadData, setAlbumUploadData] = useState(undefined);
  const [albumId, setAlbumId] = useState(contentDetails?.id);
  const [albumVisibility, setAlbumVisibility] = useState(defaultVideoVisibility);
  const [albumGroup, setAlbumGroup] = useState(contentDetails?.group_id);
  const [chooseExistingAlbum, setExistingAlbum] = useState(undefined);
  const [isAnExistingAlbum, updateAnExistingAlbum] = useState(false);
  const [albumSize, setAlbumSize] = useState(contentDetails?.total_size);
  const [albumPhotoCount, setAlbumPhotoCount] = useState(contentDetails?.photo_count);
  const [albumPublished, setAlbumPublishStatus] = useState(false);
  const [contentType, setContentType] = useState(undefined);
  const [albumStatus, setAlbumStatus] = useState(undefined);
  const [draftApiStatus, setDraftApiStatus] = useState('idle');
  const [thumbnailGenerationStatus, setThumbnailGenerationStatus] = useState(false);

  useEffect(() => {
    if (uniqueId) {
      const selectedVideo = uploadingVideoDetails?.filter((video) => video.fileId === uniqueId)[0];
      setUploadedData({
        ...uploadedData,
        title: selectedVideo?.name,
        id: selectedVideo?.id,
        uniqueIdentifier: selectedVideo?.uniqueIdentifier,
        video_size: selectedVideo?.size,
        video_duration: selectedVideo?.videoDuration,
        thumbnails: selectedVideo?.suggestedThumbnails,
        video_file: 'videofile',
        visibility: 1
      });
      setContentDetails({
        ...contentDetails,
        title: selectedVideo?.name,
        id: selectedVideo?.id,
        uniqueIdentifier: selectedVideo?.uniqueIdentifier,
        video_size: selectedVideo?.size,
        video_duration: selectedVideo?.videoDuration,
        thumbnails: selectedVideo?.suggestedThumbnails,
        video_file: 'videofile',
        visibility: 1
      });
      setVideoTitle(selectedVideo?.name);
      setVideoThumbnails(selectedVideo?.suggestedThumbnails);
      setVideoFile('video_file');
      setVideoDetails({
        name: selectedVideo?.name,
        duration: selectedVideo?.videoDuration,
        size: selectedVideo?.size,
      });
    }
  }, [uniqueId, uploadingVideoDetails]);

  const moveToNextStep = () => {
    if ((currentStep + 1) <= 3) {
      setCurrentStep(-1);
      setTimeout(() => { setCurrentStep(currentStep + 1); }, 10);
    }
  };

  const handleClose = (val) => {
    onHide(val);
    if (isSinglUepload) {
      setSingleUpload(undefined);
    }
  };

  const getToken = () => localStorage.getItem('token');
  const axios = AXIOS.create({ baseURL: baseUrl.URL, method: 'POST' });
  axios.interceptors.request.use((req) => {
    if (req?.data instanceof FormData) {
      const formData = req?.data;

      if (formData.has('token')) { formData.delete('token'); }

      formData.append('token', getToken());

      return ({ ...req, data: formData });
    }

    return ({ ...req, data: { ...req.data, token: getToken() } });
  });
  const { isValidating: uploadingVideo, mutate: uploadVideoContent } = useSWR([
    endpoints.addVideoContent, videoUploadData, source?.token], {
    fetcher: (url, formData, uniqueCancelToken) => dataFetcher(url, formData, uniqueCancelToken),
    onSuccess: ({ success, video_id, suggestedThumbnails, message, has_conversion }) => {
      if (success) {
        moveToNextStep();
        if (!videoIsUploaded) {
          setVideoID(video_id);
          setVideoUploadStatus(true);
        }
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 500);
      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong!!' });
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 500);

      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
  });

  const { isValidating: addingPhotos, mutate: addPhotoContent } = useSWR([
    endpoints.addPhotoContent, albumUploadData], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ success, total_count, has_subsription, message }) => {
      if (success) {
        if (has_subsription) {
          if (total_count) {
            setUserDetails({
              ...userDetails,
              totalContentUploaded: userDetails.totalContentUploaded > 0
                ? userDetails.totalContentUploaded + 1 : 1
            });
          }
          window.sessionStorage.setItem('tabTitle', 1);
          setTimeout(() => {
            handleClose();
            setPageLoad(true);
            localStorage.setItem('isFromUpload', true);
            navigate(Pathname.yourVideos);
          }, 4000);
        } else {
          const albID = isAnExistingAlbum ? chooseExistingAlbum : albumId;
          setTimeout(() => { handleClose(); navigate(Pathname.purchasePlan('album', albID)); }, 4000);
        }
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 200);
      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong!!' });
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 100);
      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
  });

  useEffect(() => {
    if (albumUploadData) {
      setAlbumStatus('Album Updated');
      setContentType('album');
      setAlbumPublishStatus(true);
      addPhotoContent();
    }
  }, [albumUploadData]);

  const convertBase64 = (file) => new Promise((resolve, rej) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      rej(error);
    };
  });

  const generateCustomThumbnail = async (e) => {
    const files = e?.target?.files;
    const tmpAlbumThumbnails = [];

    const file = files[0];
    const base64 = await convertBase64(file);
    tmpAlbumThumbnails.push(base64);
    setDefaultAlbumThumbnail({ 'file': file, 'url': URL.createObjectURL(file) });
    setCustomAlbumThumbnails([{ 'file': file, 'url': URL.createObjectURL(file) }]);
  };

  useEffect(() => {
    setAlbumSize(contentDetails?.total_size);
    const photoData = [];
    contentDetails?.photos?.slice(0, 5)?.forEach(async (photo) => {
      photoData.push({ 'url': photo?.photo_file });
    });
    setAlbumThumbnails(photoData);
    }, [contentDetails]);

  const { isValidating: uploadingVisibility, mutate: updateVisibilityContent } = useSWR([
    uploadType === 'draft' ? endpoints.editInprogressDraftVideo : endpoints.addVideoContent, videoVisibilityData,
  ], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ success, message }) => {
      setSelectedItems([]);
      setSingleEdit(false);
      if (success) {
        setUserDetails({
          ...userDetails,
          totalVideoUploaded: userDetails.totalVideoUploaded > 0
          ? userDetails.totalVideoUploaded + 1 : 1,
          totalContentUploaded: userDetails.totalContentUploaded > 0
            ? userDetails.totalContentUploaded + 1 : 1
        });
        setVideoPublishStatus(true);
        if (uniqueId) {
          const copyVideo = [...uploadingVideoDetails];
          const foundIndex = copyVideo.findIndex((x) => x.fileId === uniqueId);
          if (foundIndex !== -1) {
            copyVideo[foundIndex] = { ...copyVideo[foundIndex], published: 1 };
            setUploadingVideoDetails(copyVideo);
          }
        }
        sessionStorage.setItem('tabTitle', 0);
        setPubLoader(false);
        setTimeout(() => {
          handleClose('edit');
          setPageLoad(true);
          setDraftApiStatus('success');
          localStorage.setItem('isFromUpload', true);
          if (videoTitle && uploadedData?.category_id && videoVisibility && defaultVideoThumbnail) {
            navigate(`${Pathname.yourVideos}?tab=publish`);
          } else {
        navigate(`${Pathname.yourVideos}?tab=draft`);
      }
        }, 4000);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: (error) => {
      setPubLoader(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });
const [didItCalled, setDidItCalled] = useState('');

  const updateVisibility = () => {
    setPubLoader(true);
    const formData = new FormData();
    setUploadedData({
      ...uploadedData,
      ...videoVisibility !== uploadedData.visibility && { visibility: videoVisibility },
      ...(videoVisibility === '3') && { group_id: videoGroup },
    });
    uploadType === 'draft' ? formData.append('draft_content_id', contentDetails.id) : formData.append('video_id', contentDetails.id);
    videoDescription ? formData.append('description', videoDescription) : formData.append('description', 'delete');
    videoTitle && formData.append('title', videoTitle);
    videoThumbnails?.length && formData.append('thumbnail_list', videoThumbnails);
    uploadedData?.playlist_id && formData.append('playlist_id', uploadedData?.playlist_id);
    uploadedData?.category_id && formData.append('category_id', uploadedData?.category_id);
    uploadedData?.subcategory_id && formData.append('subcategory_id', uploadedData?.subcategory_id);
    if (defaultAlbumThumbnail) {
      thumbnailType === 'normal' && formData.append('thumbnails', defaultVideoThumbnail);
      thumbnailType === 'custom' && formData.append('custom_thumbnails', defaultVideoThumbnail.split('base64,')[1]);
    }
    formData.append('visibility', videoVisibility);
    formData.append('status', 1);
    if (videoVisibility === '3') {
      formData.append('group_id', videoGroup);
    }
    setVideoVisibilityData(formData);
  };

  useEffect(() => {
    // eslint-disable-next-line
    if (videoVisibilityData && currentStep === 3) {
      if (!subscriptionDetails?.has_activePlan) {
        handleClose();
        navigate(Pathname.purchasePlan('video', contentDetails.id));
        // setAppSnackbar({ isVisible: true, type: 'error', message: 'You must have purchase a plan to upload a video' });
      } else {
        setDraftApiStatus('loading');
        updateVisibilityContent();
      }
    } else if (videoVisibilityData && currentStep === 2) {
        updateVisibilityContent();
    } else if (videoVisibilityData && currentStep === 1) {
      updateVisibilityContent();
    }
  }, [videoVisibilityData]);

  const uploadVideo = async () => {
    const formData = new FormData();

    if (!videoIsUploaded) {
      setUploadedData({
        ...uploadedData,
        description: videoDescription,
        title: videoTitle,
        video_file: videoFile,
        thumbnails: videoThumbnails,
      });

      if (videoDescription) { formData.append('description', videoDescription); }
      if (videoTitle) { formData.append('title', videoTitle); }
      // if (videoThumbnails) { formData.append('thumbnails', await getBase64VideoThumbnails()); }
      if (videoThumbnails) {
        thumbnailType === 'normal' ? formData.append('thumbnails', defaultVideoThumbnail)
          : formData.append('custom_thumbnails', defaultVideoThumbnail.split('base64,')[1]);
      }
      formData.append('status', 0);
      formData.append('video_id', contentDetails?.id);
    } else {
      setUploadedData({
        ...uploadedData,
        ...videoThumbnails !== uploadedData.thumbnails && { thumbnails: videoThumbnails },
        ...videoDescription !== uploadedData.description && { description: videoDescription },
        ...videoTitle !== uploadedData.title && { title: videoTitle },
      });
      formData.append('video_id', contentDetails?.id);
      // formData.append('video_id', videoID);

      if (videoThumbnails !== uploadedData.thumbnails) {
        thumbnailType === 'normal' ? formData.append('thumbnails', defaultVideoThumbnail)
          : formData.append('custom_thumbnails', defaultVideoThumbnail.split('base64,')[1]);
      }
      if (videoDescription !== uploadedData.description) {
        formData.append('description', videoDescription);
      }
      if (videoTitle !== uploadedData.title) {
        formData.append('title', videoTitle);
      }
    }
    setVideoUploadData(formData);
  };
  const spinerSmall = 'spinex';
  const VideoThumbnails = async (e) => {
    const files = e?.target?.files;
    if (files.length === 0) {
      return; // No files selected, do nothing
    }
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
    if (!allowedTypes.includes(files[0].type)) {
      // setallowJpg(true);
      setAppSnackbar({ isVisible: true, message: 'Only allow png, jpg, webp, jpeg' });
      return;
    }
    const tmpVideoThumbnails = [];

    const file = files[0];
    const base64 = await convertBase64(file);
    tmpVideoThumbnails.push(base64);
    setDefaultVideoThumbnail(base64);
    setThumbnailType('custom');
    setCustomVideoThumbnails(tmpVideoThumbnails);
  };

  const publishAlbum = async () => {
    setDidItCalled('');
    setPubLoader(false);
    makeEveryThingNull && makeEveryThingNull();
    const formData = new FormData();
    formData.append('album_id', albumId);
    formData.append('visibility', albumVisibility);
    if (albumVisibility === '3') {
      formData.append('group_id', albumGroup);
    }
    // photoFile.forEach((photo) => {
    //   formData.append('photo_file[]', photo.file);
    // });
    if (defaultAlbumThumbnail?.file) {
      const base64 = await convertBase64(defaultAlbumThumbnail?.file);
      formData.append('icon', base64);
    } else if (defaultAlbumThumbnail?.url) {
      formData.append('icon_url', defaultAlbumThumbnail?.url);
    }
    setAlbumUploadData(formData);
  };
  const { isValidating: updatingAlbum, mutate: addEditAlbum } = useSWR([
    endpoints.addEditAlbum, albumTitle, albumDescription, source?.token, albumId], {
    fetcher: (url, title, description, uniqueCancelToken, album_id) => dataFetcher(url,
      { title: albumTitle, description: albumDescription, album_id }, uniqueCancelToken),
    onSuccess: ({ success, album_id, message }) => {
      setLoader(false);
      if (success) {
        setAlbumId(album_id);
        if (didItCalled === 'yes') {
          publishAlbum();
        } else {
          setChooseAlbumStep(2);
        }
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: () => {
      setDidItCalled('yes');
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong!!' });
    },
  });
  const moveToPreviousStep = () => {
    setCurrentStep(-1);
    setTimeout(() => { setCurrentStep(currentStep - 1); }, 10);
  };

  useEffect(() => {
    if (videoVisibility === videoVisibilityOptions[2].value) {
      document?.getElementById('group-selector')?.scrollIntoView();
    }
  }, [videoVisibility]);

  useEffect(() => {
    if (videoUploadData) { uploadVideoContent(); }
  }, [videoUploadData]);

  const addOrEditAlbum = (arr) => {
    if (arr === 'publish') {
      setPubLoader(true);
      setDidItCalled('yes');
    } else {
      setLoader(true);
    }
    addEditAlbum();
  };

  const selectGroup = (id) => {
    if (videoGroup.includes(id)) {
      setVideoGroup(videoGroup.filter((item) => item !== id));
    } else {
      setVideoGroup([...videoGroup, id]);
    }
  };

  return (
    <Fade in={isVisible}>
      <div className={styles.body}>
        <video id="video-player" style={{ display: 'none' }} />
        <input
          accept="image/*"
          disabled={uploadingVideo}
          id="video-thumbnails"
          multiple
          onChange={(e) => { VideoThumbnails(e); }}
          style={{ display: 'none' }}
          type="file"
        />
        <DialogTitle className={styles.header}>
          <div className={styles.headerContent}>
            <Text.PageTitle className={styles.headerText}>
              {videoTitle}
            </Text.PageTitle>
            {(videoIsPublished || runLoader || albumPublished) ? <></> : <CloseIcon className={styles.closeButton} onClick={handleClose} />}
          </div>
          {
            (uploadType === 'video' || uploadType === 'draft') && !videoIsPublished ? (
              <ProgressTracker currentStep={currentStep} steps={[(uploadText?.detail || 'Details'), (uploadText?.categories || 'Categories'), (uploadText?.previousDetails || 'Visibility')]} />
            ) : (
              !videoIsPublished && !albumPublished ? (
                <ProgressTracker currentStep={chooseAlbumStep} steps={[(uploadText?.detail || 'Details'), (uploadText?.summary || 'Summary')]} />
              ) : null
            )
          }
        </DialogTitle>

        {
          videoIsPublished || runLoader || albumPublished ? (
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress style={{
                height: '150px',
                width: '150px'
              }}
              />
            </Box>
          ) : (uploadType === 'video' || uploadType === 'draft') ? (
            currentStep === 1 ? (
              <EditVideo
                uploadText={uploadText}
                videoTitle={videoTitle}
                setVideoTitle={setVideoTitle}
                videoDescription={videoDescription}
                setVideoDescription={setVideoDescription}
                customVideoThumbnails={customVideoThumbnails}
                setDefaultVideoThumbnail={setDefaultVideoThumbnail}
                setThumbnailType={setThumbnailType}
                videoThumbnails={videoThumbnails}
                thumbnailGenerationStatus={thumbnailGenerationStatus}
                videoFile={videoFile}
                uploadingVideo={uploadingVideo}
                defaultVideoThumbnail={defaultVideoThumbnail}
                videoDetails={videoDetails}
                videoIsUploaded={videoIsUploaded}
                uploadedData={uploadedData}
                moveToNextStep={moveToNextStep}
                videoVisibility={videoVisibility}
                uploadVideo={uploadVideo}
                commonText={commonText}
                updateVisibility={updateVisibility}
                editFlow
              />
            ) : currentStep === 2 ? (
              <EditCategory
                videoTitle={videoTitle}
                videoVisibility={videoVisibility}
                uploadedData={uploadedData}
                setUploadedData={(data) => setUploadedData(data)}
                moveToNextStep={moveToNextStep}
                moveToPreviousStep={moveToPreviousStep}
                currentStep={currentStep}
                videoID={contentDetails?.id}
                uploadText={uploadText}
                commonText={commonText}
                defaultVideoThumbnail={defaultVideoThumbnail}
                updateVisibility={updateVisibility}
                editFlow
              />
            ) : currentStep === 3 ? (
              <EditVisibility
                modalType="edit"
                setVideoVisibility={setVideoVisibility}
                videoTitle={videoTitle}
                uploadedData={uploadedData}
                draftApiStatus={draftApiStatus}
                videoVisibility={videoVisibility}
                defaultVideoThumbnail={defaultVideoThumbnail}
                videoDetails={videoDetails}
                moveToPreviousStep={moveToPreviousStep}
                updateVisibility={updateVisibility}
                videoVisibilityOptions={videoVisibilityOptions}
                selectGroup={selectGroup}
                editFlow
                videoGroup={videoGroup}
              />
            ) : null
          ) : (
            chooseAlbumStep === 1 ? (
              <AlbumDetails
                setChooseAlbumStep={setChooseAlbumStep}
                setAlbumTitle={setAlbumTitle}
                spinerSmall={spinerSmall}
                loader={loader}
                pubLoader={pubLoader}
                albumTitle={albumTitle}
                albumDescription={albumDescription}
                setAlbumDescription={setAlbumDescription}
                customAlbumThumbnails={customAlbumThumbnails}
                defaultAlbumThumbnail={defaultAlbumThumbnail}
                setDefaultAlbumThumbnail={setDefaultAlbumThumbnail}
                generateCustomThumbnail={generateCustomThumbnail}
                albumThumbnails={albumThumbnails}
                addOrEditAlbum={addOrEditAlbum}
                didItCalled={didItCalled}
                albumSize={albumSize}
                albumPhotoCount={albumPhotoCount}
                uploadText={uploadText}
                commonText={commonText}
                publishAlbum={publishAlbum}
                addingPhotos={addingPhotos}
                isUpdating
              />
            ) : chooseAlbumStep === 2 ? (
              <AlbumSummary
                videoVisibilityOptions={videoVisibilityOptions}
                albumVisibility={albumVisibility}
                setAlbumVisibility={setAlbumVisibility}
                selectGroup={selectGroup}
                videoGroup={videoGroup}
                publishAlbum={publishAlbum}
                defaultAlbumThumbnail={defaultAlbumThumbnail}
                albumSize={albumSize}
                albumPhotoCount={albumPhotoCount}
                addingPhotos={addingPhotos}
                uploadText={uploadText}
                commonText={commonText}
                visibilityText={visibilityText}
                setChooseAlbumStep={setChooseAlbumStep}
                chooseAlbumStep={chooseAlbumStep}
                isUpdating
              />
            ) : (
              null
            )
          )

        }
      </div>
    </Fade>
  );
};
export default Modal;
