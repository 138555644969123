import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { endpoints } from 'Api';
import { Pathname } from 'Routes';
import styles from './index.module.css';

export const GroupSelector = ({ isBulk, id: groupID, onSelect, selectedOption = null, setOpenGroups, handleMenuItemClick, index }) => {
  const location = !isBulk && useLocation();

  const [options, setOptions] = useState([]);

  const { isValidating: loadingGroups, mutate: fetchGroups } = useSWR(endpoints.getMyGroups, {
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setOptions(data);
      } else {
        setOptions([]);
      }
    },
    onError: () => {
      setOptions([]);
    },
  });

  useEffect(() => {
    fetchGroups();
    // if (!isBulk && !location.search?.includes(Pathname.createGroup)) {
    //   fetchGroups();
    // }
  }, [!isBulk && location.search]);

  const styleForAbsolute = {
    position: 'absolute',
    zIndex: 100000000,
    background: 'white'
  };
  return (
    <div style={handleMenuItemClick ? styleForAbsolute : {}} className={styles.groupContainer} id={groupID}>
      {handleMenuItemClick
      && (
          <div
            onKeyPress={undefined}
            role="button"
            className={styles.right_close}
            tabIndex={0}
            onClick={() => handleMenuItemClick(index)}
          >
          <CloseIcon />
          </div>
      )}
      {loadingGroups
        ? (
          <div className={styles.group}>
            <CheckBoxOutlineBlankIcon style={{ opacity: 0 }} />
            &nbsp;&nbsp;
            <>Loading ...</>
          </div>
        )
        : options?.map(({ title, id }) => (
          <div
            className={styles.group}
            key={id}
            onClick={() => { onSelect(id); }}
            onKeyPress={undefined}
            role="button"
            tabIndex={0}
          >
            {selectedOption && selectedOption.length > 0 && selectedOption.includes(id)
              ? <CheckIcon />
              : <CheckBoxOutlineBlankIcon />}
            &nbsp;&nbsp;
            {title}
          </div>
        ))}
      <br />
      {!isBulk
      && (
          <Link className={cn({ [styles.group]: true, [styles.addGroup]: true })} to={Pathname.createGroup}>
          <CheckBoxOutlineBlankIcon style={{ opacity: 0 }} />
          &nbsp;&nbsp;
          <>New Group</>
          </Link>
        )}
    </div>
  );
};
